import cn from 'classnames';
import PropTypes from 'prop-types';
import useDeviceType from '@/hooks/useDeviceType';
import { Image, Link } from '@/components/atoms';
import styles from './footerBanners.module.scss';

const FooterBanners = ({ banners, withFilter = false }) => {
  const { isDesktop } = useDeviceType();
  const banner = cn({
    [styles.banner]: true,
    [styles.withFilter]: withFilter
  });

  return (
    <div className={styles.container}>
      {banners?.map((b) => (
        <div
          key={b.url}
          className={banner}
        >
          <Link href={`${b.url[0] === '/' ? '' : '/'}${b.url}`}>
            {/* TODO: Como máximo 500 pixeles de alto, cambiar desde la api */}
            <Image
              width={isDesktop ? 600 : 384}
              height={isDesktop ? 900 : 576}
              alt={b.alt_text}
              src={isDesktop ? b.desktop_image.url : b.mobile_image.url}
              cover
            />
          </Link>
        </div>
      ))}
    </div>
  );
};

FooterBanners.propTypes = {
  banners: PropTypes.array.isRequired,
  withFilter: PropTypes.bool
};

export default FooterBanners;
